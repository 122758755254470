import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchInsuranceData } from './apiRoute';
import { Header } from '../components';

const Insurance = () => {
  const [refillData, setRefillData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchInsuranceData();
        setRefillData(response.data.data.user);
        console.log(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const formatDate = (timestamp) => {
  if (timestamp <= 0) {
    return ''; 
  }

  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const columns = [
  {
    dataField: 'count',
    text: '#',
    formatter: (cell, row, rowIndex) => rowIndex + 1, 
    headerStyle: { width: '50px' } 
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
  },
   {
    dataField: 'package',
    text: 'Package',
    sort: true,
  },
  {
    dataField: 'time_in',
    text: 'Time In',
    sort: true,
    formatter: (cell, row) => formatDate(cell),
  },
 
];


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Orders" title="Insurance Orders"/>
      <div>
        <BootstrapTable
          keyField='email'
          data={refillData}
          columns={columns}
          striped
          hover
          condensed
          pagination={paginationFactory()}
        />
      </div>
    </div>
  );
}

export default Insurance;
