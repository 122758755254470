import apiRequest from "./apiRequest";

async function fetchRefillData() {
  const response = await apiRequest("GET", `api/admin/refill_data/`);
  return response;
}

async function fetchSubscribersData() {
  const response = await apiRequest("GET", `api/admin/subscribers_data/`);
  return response;
}

async function fetchUsersData() {
  const response = await apiRequest("GET", `api/admin/user_data/`);
  return response;
}

async function fetchInsuranceData() {
  const response = await apiRequest("GET", `api/admin/bima_data/`);
  return response;
}

async function fetchPaystackData() {
  const response = await apiRequest("GET", `api/admin/paystack_data/`);
  return response;
}

async function fetchCouponData() {
  const response = await apiRequest("GET", `api/admin/coupon_data/`);
  return response;
}

async function fetchContactData() {
  const response = await apiRequest("GET", `api/admin/contact_data/`);
  return response;
}

export {
  fetchRefillData,
  fetchSubscribersData,
  fetchUsersData,
  fetchInsuranceData,
  fetchPaystackData,
  fetchCouponData,
  fetchContactData,
};
