import React from 'react'
import {ChartComponent,SeriesCollectionDirective,SeriesDirective,Inject,SplineAreaSeries,DateTime,Legend} from '@syncfusion/ej2-react-charts';
import {areaCustomSeries, areaPrimaryYAxis, areaPrimaryXAxis} from '../../data/dummy';
import {useStateContext} from '../../contexts/ContextProvider';
import { ChartHeader } from '../../components';

const Area = () => {
  
  const {currentMode} = useStateContext();

  return (
    <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <ChartHeader category="Chart" title="Area" description="Inflation Rate in percentage"/>
      <ChartComponent
        id="line-chart"
        height="420px"
        primaryXAxis={areaPrimaryXAxis}
        primaryYAxis={areaPrimaryYAxis}
        chartArea={{border: {width: 0}}}
        tooltip={{enable: true}}
        background={currentMode === 'Dark' ? '#33373E' : ''}
        legendSettings={{background: 'white'}}
      >
        <Inject services={[SplineAreaSeries,DateTime,Legend]}/>
        <SeriesCollectionDirective>
          {areaCustomSeries.map((item,index) => 
            <SeriesDirective key={index} {...item}/>
          )}
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  )
}

export default Area