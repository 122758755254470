import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchSubscribersData } from './apiRoute';
import { Header } from '../components';

const Subscribers = () => {
  const [refillData, setRefillData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSubscribersData();
        setRefillData(response.data.data.user);
        console.log(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      dataField: 'count',
      text: '#',
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Counting starts from 1
      headerStyle: { width: '50px' } // Adjust width as needed
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
   
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Orders" title="Subscribers"/>
      <div>
        <BootstrapTable
          keyField='email'
          data={refillData}
          columns={columns}
          striped
          hover
          condensed
          pagination={paginationFactory()}
        />
      </div>
    </div>
  );
}

export default Subscribers;
