import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchUsersData } from './apiRoute';
import { Header } from '../components';

const Users = () => {
  const [refillData, setRefillData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUsersData();
        setRefillData(response.data.data.user);
        console.log(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const formatDate = (timestamp) => {
  if (timestamp <= 0) {
    return ''; 
  }

  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const columns = [
  {
    dataField: 'count',
    text: '#',
    formatter: (cell, row, rowIndex) => rowIndex + 1, 
    headerStyle: { width: '50px' } 
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
  },
  {
    dataField: 'reg_complete',
    text: 'Registration Complete',
    sort: true,
    formatter: (cell, row) => {
      if (cell === 2) {
        return 'Complete';
      } else {
        return 'Incomplete';
      }
    },
  },
  {
    dataField: 'firstname',
    text: 'First Name',
    sort: true,
  },
  {
    dataField: 'lastname',
    text: 'Last Name',
    sort: true,
  },
  {
    dataField: 'address',
    text: 'Address',
    sort: true,
  },
  {
    dataField: 'phone',
    text: 'Phone',
    sort: true,
  },
  {
    dataField: 'town_name',
    text: 'Town Name',
    sort: true,
  },
  {
    dataField: 'postalcode',
    text: 'Postal Code',
    sort: true,
  },
  {
    dataField: 'gender',
    text: 'Gender',
    sort: true,
  },
  {
    dataField: 'date_of_birth',
    text: 'Date of Birth',
    sort: true,
    formatter: (cell, row) => formatDate(cell),
  },
  {
    dataField: 'can_admin',
    text: 'Can Admin',
    sort: true,
  },
];



  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Orders" title="Users"/>
      <div>
        <BootstrapTable
          keyField='email'
          data={refillData}
          columns={columns}
          striped
          hover
          condensed
          pagination={paginationFactory()}
        />
      </div>
    </div>
  );
}

export default Users;
