import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchRefillData } from './apiRoute';
import { Header } from '../components';

const Orders = () => {
  const [refillData, setRefillData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchRefillData();
        setRefillData(response.data.data.user);
        console.log(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

 const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const columns = [
  {
    dataField: 'order_number',
    text: 'Order Number',
    sort: true,
  },
  {
    dataField: 'tracking_id',
    text: 'Tracking Id',
  },
  {
    dataField: 'medicine',
    text: 'Medicine',
  },
  {
    dataField: 'email',
    text: 'Email',
  },
  {
    dataField: 'a_price',
    text: 'CP (₵)',
  },
  {
    dataField: 'payment',
    text: 'SP (₵)',
  },
  {
    dataField: 'profit',
    text: 'Profit (₵)',
    formatter: (cell, row) => `₵ ${(row.payment - row.a_price).toFixed(2)}`,
  },
  {
    dataField: 'delivery_status',
    text: 'Status',
  },
  {
    dataField: 'payment_status',
    text: 'Payment Status',
  },
  {
    dataField: 'time_in',
    text: 'Time In',
    formatter: (cell, row) => formatDate(cell),
  },
];


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Orders" title="Get My Medicine Orders"/>
      <div>
        <BootstrapTable
          keyField='order_number'
          data={refillData}
          columns={columns}
          striped
          hover
          condensed
          pagination={paginationFactory()}
        />
      </div>
    </div>
  );
}

export default Orders;
